import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/footer.css';
import App from './App';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './components/redux/Store';

const root = ReactDOM.createRoot(document.getElementById('root'));




root.render(

  <Provider store={store}>
    <BrowserRouter>
    {/* <BrowserRouter basename={"/"}> */}
      <App />
    </BrowserRouter>
  </Provider>
);
